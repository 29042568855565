<template>
  <div>
   <table style="width:100%;" v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true">
     <tr>
       <td><input class="normalInput" ref="kaorderName" type="text" placeholder="받는 분 성함" v-model="kaorderName" @input="kaorderName = kaorderName.trim()" ></td>
       <td><input class="normalInput" ref="kaorderEmail" type="text" placeholder="이메일" v-model="kaorderEmail" @input="kaorderEmail = kaorderEmail.trim()" ></td>
       <td><input class="normalInput" ref="kaorderClearance" type="text" placeholder="개인통관고유부호"  v-model="kaorderClearance" @input="kaorderClearance = kaorderClearance.trim()" ></td>
       <td style="text-align: right;">
          <Button @click="search" label="조회" raised size="small" />
        </td>
     </tr>
     <tr>
       <td colspan="4">
         <div class="container_login" >
           <div class="container_sub_style">
             <div class="tooltip">주문접수
               <span class="tooltiptext tooltip-bottom">구매자 님의 주문이 확인되었고, 해외 판매자에게 구매요청해놓은 상태입니다.</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(0, val0)" class="link">{{val0}}</a> 건
             </span>
           </div>
           <div class="container_sub_style">
             <div class="tooltip">입금대기
               <span class="tooltiptext tooltip-bottom">구매자 님께서 GEEK9로 결제를 해주시면 해외 판매자에게 물건을 구매할 수 있는 상태입니다.</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(1, val1)" class="link">{{val1}}</a> 건
             </span>
           </div> 
         </div>
         <div class="container_login">
           <div class="container_sub_style">
             <div class="tooltip">입금완료
               <span class="tooltiptext tooltip-bottom">구매자 님의 결제 내역이 확인된 상태입니다. 곧 해외 판매자에게서 상품을 구매합니다.</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(2, val2)" class="link">{{val2}}</a> 건
             </span>
           </div>
           <div class="container_sub_style">
             <div class="tooltip">구매완료
               <span class="tooltiptext tooltip-bottom">해외 판매자에게서 상품 구매가 완료된 상태입니다. 해외 판매자가 상품을 보낼 때까지 기다려 주시기 바랍니다.</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(3, val3)" class="link">{{val3}}</a> 건
             </span>
           </div> 
         </div>
         <div class="container_login">
           <div class="container_sub_style">
             <div class="tooltip">입고대기
               <span class="tooltiptext tooltip-bottom">해외 판매자가 상품을 배송 보냈지만 아직 해당 상품이 GEEK9 창고에 도착하기 전 상태입니다.</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(4, val4)" class="link">{{val4}}</a> 건
             </span>
           </div>
           <div class="container_sub_style">
             <div class="tooltip">창고도착
               <span class="tooltiptext tooltip-bottom">판매자가 보낸 상품이 GEEK9 창고에 도착한 상태입니다. 한국으로 보내는 배송비 안내를 곧 받으실 수 있습니다. 조금만 더 기다려주세요!</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(5, val5)" class="link">{{val5}}</a> 건
             </span>
           </div> 
         </div>
         <div class="container_login">
           <div class="container_sub_style">
             <div class="tooltip">배송중
               <span class="tooltiptext tooltip-bottom">상품이 한국으로 배송 중입니다. 한국 세관을 거친 후에 구매자 님께 배송됩니다.</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(6, val6)" class="link">{{val6}}</a> 건
             </span>
           </div>
           <div class="container_sub_style">
             <div class="tooltip">거래완료
               <span class="tooltiptext tooltip-bottom">구매자 님께서 상품을 정상적으로 수령한 상태입니다.</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(7, val7)" class="link">{{val7}}</a> 건
             </span>
           </div> 
         </div>
         <div class="container_login">
           <div class="container_sub_style">
             <div class="tooltip">거래취소
               <span class="tooltiptext tooltip-bottom">해외 판매자 혹은 구매자 님의 요청으로 거래가 취소된 상태입니다. 해외 결제라서 환불까지 최소 한 달의 시간이 필요합니다.</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(-1, val8)" class="link">{{val8}}</a> 건
             </span>
           </div>
           <div class="container_sub_style">
             <div class="tooltip">환불완료
               <span class="tooltiptext tooltip-bottom">구매자 님의 계좌로 환불이 완료된 상태입니다.</span>
             </div>
             <span class="status_cnt">
               <a @click="setOrder(-2, val9)" class="link">{{val9}}</a> 건
             </span>
           </div> 
         </div>   
       </td>
     </tr>
     <tr>
      <td colspan="4">
        <div class="container_no_login" style="color:red">최근 6개월 내역만 조회됩니다.</div>
      </td> 
    </tr>
   </table>
   <login v-else @login="login" pageKey="kaorder" /> 
  </div>
 </template>
 
 <script>
 import login from '@/components/common/Login.vue'

 export default {
  emits: ["setOrder", "login"],
  components: {
    login
  },
  setup() {
    return {
    }
  },
   mounted() {
     this.api.setPage('list')
     console.log(this.api.getCookies('page'))
    //  this.$emit("menu_click", 0)
   },
   data() {
     return { 
       date: 'r1',
       list2Key: 0,
       page: 1,
       status: -1,
       list: Array(),
       fixedName:'',
       fixedMail:'',
       fixedClearance:'',
       val0:'', val1:'', val2:'', val3:'', val4:'', val5:'', val6:'', val7:'', val8:'', val9:'',
       kaorderEmail:'',
       kaorderName:'',
       kaorderClearance:''
     }
   },
   methods: {
    login() {
      this.$emit('login')
    },
    async setOrder(status, cnt) {
      if(cnt !== 0) {
        let rangeJson = this.api.getRange('r3')   // 최근 6개월 고정
        await this.api.axiosGql('SELECT_DISTINCT_ORDER_LIST2', {type:'SELECT_DISTINCT_ORDER_LIST2', id: "", startdate: rangeJson.startDate, enddate: rangeJson.endDate, status: status, page: 1, uidList:this.list})
        .then( (res) => {
          res.data.map(order => {
            let country = order.COUNTRY
            let currency = ' EUR'
            if(country === '홍콩') currency = ' HKD'
            else if(country === '영국') currency = ' 파운드'
            else if(country === '미국') currency = ' USD'
            else if(country === '일본') currency = ' 엔'
            else if(country === '중국') currency = ' 위안화'
            else if(country === '싱가포르') currency = ' SGD'
            else if(country === '호주') currency = ' AUD'
            else if(country === '뉴질랜드') currency = ' NZD'
            else if(country === '캐나다') currency = ' CAD'
            else if(country ==='대만') currency = ' TWD'
            // else if(country ==='태국') currency = ' THB'
            // else if(country === '덴마크') currency = ' DKK'
            // else if(country === '베트남') currency = ' VND'
            else if(country === '기타') currency = ''
            
            order.ORIGIN_PRICE = order.ORIGIN_PRICE + currency
            let tmpStatus = order.STATUS

            if(tmpStatus !== undefined) order.STATUS = this.api.getOrderStatus(tmpStatus)

            let invoiceNumber = order.INVOICE_NUMBER
            let deliveryKrCompany = order.DELIVERY_KR_COMPANY

            if(invoiceNumber !== undefined && deliveryKrCompany !== undefined && (tmpStatus === 6 || tmpStatus === 7) ) order.INVOICE_NUMBER = this.api.getDeliveryUrl(invoiceNumber, deliveryKrCompany)
          })
          this.$emit("setOrder", res.data, status, this.list)
        })
        .catch( (err) => {
          console.log(err)
        })
      } else {
        this.$notify(this.api.getOrderStatus(status) + ' 내역이 없습니다.')
      }
    },
     async search() {
       this.fixedName = this.kaorderName
       this.fixedMail = this.kaorderEmail
       this.fixedClearance = this.kaorderClearance 
      
       if(this.fixedName == '' || this.fixedMail == '' || this.fixedClearance == '') this.$notify('입력하지 않은 항목이 있습니다.')
       else this.getOrderInfo({name:this.fixedName, mail:this.fixedMail, clearance:this.fixedClearance})
     },
     async getOrderInfo(param) {
       const id = this.api.getCookies('id')
       if(!(this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true)) {
         this.$notify('로그인해주세요')
       } else if(id) {
        await this.api.axiosGql('SELECT_ORDER_INFO_BY_CONDITION', {type:'SELECT_ORDER_INFO_BY_CONDITION', id: "", name:param.name, mail:param.mail, clearance:param.clearance })
         .then( async (res) => {
          let json = res.data
          this.list = Array()
          // json to list
          await json.map( async(item) => {
            this.list.push(item.UID)
          })
          const rangeResult = this.api.getRange('r3')   // 6개월로 고정
          await this.api.axiosGql('SELECT_ORDER_STATUS_CNT2', {type:'SELECT_ORDER_STATUS_CNT2', id: ""+id, startdate: rangeResult.startDate, enddate: rangeResult.endDate, name:param.name, mail:param.mail, clearance:param.clearance, uidList:this.list })
          .then( (res) => {
            const json = res.data
            // 주문접수(0)/입금대기(1)/입금완료(2)/구매완료(3)/입고대기(4)/창고도착(5)/배송중(6)/거래완료(7)/거래취소(8)/환불완료(9)
            this.val0 = json.ORDER_CHECK.length
            this.val1 = json.DEPOSIT_READY.length
            this.val2 = json.DEPOSIT_CMPL.length
            this.val3 = json.PURCHASE_CMPL.length
            this.val4 = json.WAREHOUSING.length
            this.val5 = json.DELIVERY_READY.length
            this.val6 = json.DELIVERY_ING.length
            this.val7 = json.TRANSACTION_CMPL.length
            this.val8 = json.TRANSACTION_CNCL.length
            this.val9 = json.REFUND_CMPL.length
          })
          .catch( (err) => {
            console.log(err)
          })
        })
       }
     },
     getCookies(key) {
       return this.api.getCookies(key)
     },
    //  async request() {
    //    window.Kakao.API.request({
    //      url: '/v2/user/me',
    //      success: async (response) => {
    //       let flag = false
    //       await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:response.id })
    //       .then( async (res) => {
    //         const result = res.data
    //         if(result === 'null' || result === undefined || result === null ) flag = false
    //         else flag = true
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //       if(flag) {
    //         this.$parent.$emit("setCookies", {login:true, id:response.id, nickname:response.properties.nickname, img:response.properties.thumbnail_image, mail:response.kakao_account.email})
    //       } else this.showRegistModal(response.id)
    //     },
    //     fail: (error) => {
    //       console.log(error)
    //     }
    //    })
    //  },
    //  async login() {
    //    window.Kakao.Auth.login({
    //      success: () => {
    //        this.request()
    //      },
    //      fail: () => {
    //        this.$notify("다시 시도해주세요.")
    //      },
    //    })
    //  }, 
   }
 }
 </script>
 
 <style scoped>
 
 tr {
   width:100%
 }
 
 td {
   padding-top: 10px; 
   padding-bottom: 10px;
   padding-right:10px;
   min-width:90px;
 }
 </style>
 